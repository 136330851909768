import { Routes } from '@angular/router';
import { LayoutComponent } from '../components/layout/layout.component';
export const routeUrls: Routes = [
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () =>
      import('../components/pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('../components/pages/search/search.module').then(
        (m) => m.SearchModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('../common-components/sign-in/sign-in.module').then(
        (m) => m.SignInModule
      ),
  },
  {
    path: 'sign-up',
    loadChildren: () =>
      import('../common-components/sign-up/sign-up.module').then(
        (m) => m.SignUpModuleModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import(
        '../common-components/forgot-password/forgot-password.module'
      ).then((m) => m.ForgotPasswordModule),
  },
  {
    path: 'reset-password/:id',
    loadChildren: () =>
      import('../common-components/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'verify-email/:email',
    loadChildren: () =>
      import('../common-components/verify-email/verify-email.module').then(
        (m) => m.VerifyEmailModule
      ),
  },
  {
    path: 'vendor-signup/panama',
    loadChildren: () =>
      import('../common-components/vendor-signup/vendor-signup.module').then(
        (m) => m.VendorSignupModule
      ),
  },
  {
    path: 'vendor-signup/india',
    loadChildren: () =>
      import(
        '../common-components/vendor-signup-india/vendor-signup-india.module'
      ).then((m) => m.VendorSignupIndiaModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('../common-components/page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundModule
      ),
  },
];
