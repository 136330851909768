import {
  Component,
  OnInit,
  ViewEncapsulation,
  Renderer2,
  Inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CartService } from 'src/app/services/cart/cart.service';
import { RequestService } from 'src/app/services/http/request.service';
import { Router } from '@angular/router';
import { USER_PROFILE } from 'src/environments/api-path';
import { SubSink } from 'subsink';
import { StorageCookieService } from 'src/app/services/cookie/storage-cookie.service';

@Component({
  selector: 'app-new-header-account',
  templateUrl: './new-header-account.component.html',
  styleUrls: ['./new-header-account.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewHeaderAccountComponent implements OnInit {
  rightMenu = false;
  is_login: boolean;
  loading = false;
  username;
  subs = new SubSink();
  userData;
  ImgProfile: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private auth: AuthService,
    private cartdata: CartService,
    private request: RequestService,
    private router: Router,
    private cookie: StorageCookieService
  ) {}

  ngOnInit(): void {
    this.is_login = this.auth.isAuthenticated();
    this.getUser();
  }
  getUser() {
    this.loading = true;
    this.subs.add(
      this.request.POST(USER_PROFILE, {}).subscribe(
        (response) => {
          if (response['status']) {
            this.userData = response['record'];

            this.ImgProfile = this.userData.profile_image;

            this.loading = false;
          }
        },
        (err) => {
          this.loading = false;
        }
      )
    );
  }
  openRightSidebar() {
    this.username = this.auth.getCookie('USERNAME');
    this.rightMenu = true;
    this.renderer.addClass(this.document.body, 'no-scroll');
  }

  closeRightSidebar() {
    this.rightMenu = false;
    this.renderer.removeClass(this.document.body, 'no-scroll');
  }

  logout() {
    this.loading = true;
    this.request.GET('logout', {}).subscribe(
      (response) => {
        this.loading = false;
        if (response['status']) {
          var unique_id = this.auth.gettokenforcart();
          this.cartdata.getCartcount(unique_id, false);
          this.auth.logOut();
          this.router.navigate(['login']);
        }
      },
      (err) => {
        //console.log(err);
        this.loading = false;
      }
    );
  }
}
